<template>
  <div>
    <div style="width: 1440px;margin: 0 auto;margin-top:110px;">
      <div style="height: auto;width: 90%;margin: 0px 5%;background-color: white;">
        <div style="min-height:calc(100vh - 110px);width: 100%;margin: 0 auto;overflow: hidden; position: relative">
          <div style=" border-top: 30px solid #031F88;border-right: 30px solid transparent;width:0;height:0; position:absolute;top:0;left:0;"></div>
          <div style="height: 50px;line-height: 50px;border-bottom: solid 1px darkgray;display: flex; justify-content: space-between; ">
            <div style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;margin: 0 20px">
                  <div class="hand op" @click="return_list" style="font-size: 18px;font-weight: bold;">
                    <i class="el-icon-back"></i>
                    <span v-if="$route.query.type"> 资料搜索</span>
                    <span v-else> 标签检索--{{name}}</span>
                  </div>
                  <div v-if="!$route.query.type" style="margin: 0 0 0 20px; color: #8A8D91">|</div>
                  <div v-if="!$route.query.type" class="room_tab hand" :class="formtype===0?'room_type_bottom room_tab_change':''"
                       @click="file_type_change(0)">全部
                  </div>
                  <div v-if="!$route.query.type" class="room_tab hand" :class="formtype===1?'room_type_bottom room_tab_change':''"
                       @click="file_type_change(1)">标准规范
                  </div>
                  <div v-if="!$route.query.type" class="room_tab hand" :class="formtype===2?'room_type_bottom room_tab_change':''"
                       @click="file_type_change(2)">政策法规
                  </div>
                  <div v-if="!$route.query.type" class="room_tab hand" :class="formtype===3?'room_type_bottom room_tab_change':''"
                       @click="file_type_change(3)">项目案例
                  </div>
                  <div v-if="!$route.query.type" class="room_tab hand" :class="formtype===4?'room_type_bottom room_tab_change':''"
                       @click="file_type_change(4)">文章专著
                  </div>
            </div>
            <div style="display: flex; margin:0 5px; align-items: center;" >
                  <el-button v-if="is_login == 1 && !$route.query.type" type="success" size="small" round @click="getCollection">我的收藏</el-button>
                  <el-button v-if="is_login == 1 && !$route.query.type" type="info" size="small" round @click="getUpload">我的上传</el-button>
                  <el-button v-if="is_login == 1 && !$route.query.type" type="warning" size="small" style="margin-right: 10px" round @click="getPay">我的购买</el-button>
                  <div style="width: 240px">
                    <el-input placeholder="请输入内容" size="small" v-model="content" class="input-with-select">
                      <div class="hand op sc" style="width: 20px; text-align: center" @click="dataSearch" slot="append">
                        <i class="el-icon-search"></i>
                      </div>
                    </el-input>
                  </div>
             </div>
          </div>
            <div v-if="$route.query.type">
                <div style="display: flex">
                  <div style="font-size: 18px;font-weight: bold;margin-left: 40px;margin-top: 20px;">资料分类：</div>
                  <div style="margin-top: 20px;">
                      <el-radio-group v-model="codetype" style="font-weight: 600" @change="dataclassification">
                          <el-radio class="hand op sc" :label="1">标准规范</el-radio>
                          <el-radio class="hand op sc" :label="2">政策法规</el-radio>
                          <el-radio class="hand op sc" :label="3">项目案例</el-radio>
                          <el-radio class="hand op sc" :label="4">文章专著</el-radio>
                          <el-radio class="hand op sc" :label="5">图片</el-radio>
                          <el-radio class="hand op sc" :label="6">视频</el-radio>
                          <el-radio class="hand op sc" :label="7">素材</el-radio>
                      </el-radio-group>
                  </div>
                </div>
            </div>
          <div style="height: auto;overflow: hidden;margin: 4px 20px 0 20px" v-if="!$route.query.type">
            <div style="float: left;width: 10%;line-height: 46px;height: auto;">
              <span style="font-size: 18px; font-weight: 600">资料类型：</span>
            </div>
            <div style="float: left;width: 90%;height: auto;">
              <div
                  style="text-align: center;overflow: hidden;width: 12%;  padding-right: 1%; float: left;margin: 10px 0px;">
                <div class="label  hand op sc" style="width: 100%;"
                     :style="type_index===0?'background-color:#031f88;color:#ffffff':''" @click="type_change(fid,0)">全部
                </div>
              </div>
              <div style="text-align: center;overflow: hidden;width: 12%;padding-right: 1%; float: left;margin: 10px 0px;" v-for="(item,index) in type3_list" v-bind:key="index">
                <div class="label hand op sc" style="width: 100%;cursor: pointer"
                     :style="type_index===index+1?'background-color:#031f88;color:#ffffff':''"
                     @click="type_change(item.value,index+1)">{{ item.label }}
                </div>
              </div>
            </div>
          </div>
          <div style="margin: 10px 20px 0 20px;min-height: 1000px;">
            <div style="display: flex;flex-wrap: wrap">
              <div v-if="codetype === 1 || codetype === 2 || codetype === 3 || codetype === 4" v-for="(label,index) in content_list" v-bind:key="index" style="width: calc((100% - 20px) / 2); margin: 10px 10px 10px 0">
                  <FileCard :data="label"></FileCard>
              </div>
                <!--              <div v-else style="text-align: center; width: 100%">-->
                <!--                  <NullBox data="数据"></NullBox>-->
                <!--              </div>-->
            </div>
            <div v-if="codetype === 5 || codetype === 6" v-for="(item,index) in content_list" :key="index" style="width:352px;float: left;height: 356px;margin-left: 30px;margin-bottom: 20px;margin-right: 35px;">
                <!--  图片  -->
                <div v-if="codetype === 5" style="width: 96%;margin: 2%;height:100% ;background-color: white;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);border-radius: 8px;overflow:hidden;">
                  <div @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                      <div style="position: relative;">
                          <img :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                      </div>
                      <div v-if="item.payAmt > 0" style="position: absolute; top: 0; left: 0;z-index: 10;">
                          <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                      </div>
                      <div v-if="item.uuid != user.uuid">
                          <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                          <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                      </div>
                  </div>
                  <div style="width: 100%;height: auto;">
                      <div style="width: 60%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold">{{item.title}}</div>
                      <div >
                          <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                      </div>
                  </div>

                  <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top: 5px;float:left;">
                      <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
                      <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
                  </div>
                  <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float:left;">
                      <div v-if=" typeof item.user == 'undefined'" >
                          <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                              <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                          </div>
                          <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
                      </div>
                      <div v-else>
                          <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                              <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                          </div>
                          <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
                      </div>
                      <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 18px;font-size: 20px;margin-right: 8px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
                      <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 14px;font-size: 23px;margin-right: 8px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>
                  </div>
              </div>
                <!--  视频  -->
                <div v-if="codetype === 6" style="width: 96%;margin: 2%;height:100%;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);position: relative;">
                    <div @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                        <div>
                            <img :src="item.cover" style="width:100%;height:229px;object-fit: cover;">
                        </div>
                        <div v-if="item.payAmt > 0 " style="position: absolute; top: 0; left: 0;z-index: 10;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                        </div>
                        <div v-if="item.uuid != user.uuid">
                            <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                            <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                        </div>
                        <div style="margin-top: -145px;margin-left:135px;position: absolute;"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon13.png" style="width: 64px;height: 64px"></div>
                    </div>
                    <div style="width: 100%;height: auto;">
                        <div style="width: 60%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold">{{item.title}}</div>
                        <div >
                            <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                        </div>
                    </div>
                    <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top:5px;float: left;">
                        <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
                        <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
                    </div>
                    <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float: left;">
                        <div v-if=" typeof item.user == 'undefined'" >
                            <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                            </div>
                            <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
                        </div>
                        <div v-else>
                            <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                            </div>
                            <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
                        </div>
                        <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 18px;font-size: 20px;margin-right: 8px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
                        <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 14px;font-size: 23px;margin-right: 8px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>
                    </div>
                </div>
            </div>
            <!--  素材  -->
            <div v-if="codetype === 7">
              <div id="enclosure_list" v-if="content_list.length>0">
                  <div class="col" v-for="number in [0,1,2,3]">
                      <div class="folder" v-for="(item,index) in content_list"  :key="index"  v-if="index%4===number" >
                          <el-popover trigger="hover" placement="bottom"  width="325" style="width: 325px;">
                              <div style="width: 100%;height: auto;text-align: center;font-size: 18px;font-weight: bold">{{item.title}}</div>
                              <div class="fileList">
                                  <div class="rolling">
                                      <div class="file hand" v-for="(i,idx) in item.enclosure" :key="idx" style="height:100%; cursor: pointer;background: #e3d8d8;margin-bottom: 8px; overflow: hidden;border-radius: 3px;">
                                          <div style="float: left;width: calc(100% - 50px);line-height: 40px;">
                                              <div class="el-icon-paperclip" style="float: left;font-size: 16px;margin:0 3px;line-height: 40px;color: #67c23a"></div>
                                              <el-tooltip effect="light" :content="i.name" placement="top" style="float: left;">
                                                  <div style="width: 90%;overflow: hidden;text-overflow :ellipsis;white-space: nowrap;height: 40px;line-height: 40px;">{{i['name']}}</div>
                                              </el-tooltip>
                                          </div>
                                          <el-button  @click="download(i['url'],i['name'],i['hash'],item.id,item.PayStatus,item.payAmt)" :disabled="isDownloading" style="border: none;background: #e3d8d8;width: 18px;height: 18px;"><i style="font-size: 18px;" class="el-icon-upload"></i></el-button>
                                      </div>
                                  </div>
                              </div>
                              <div class="folderImgAndName" slot="reference">
                                  <div class="folderImg" >
                                      <i class="el-icon-folder-add"  style="font-size: 25px;color: #f8ae00"></i>
                                      <!--		                        <i v-else class="el-icon-folder-remove"  style="font-size: 25px;color: #f8ae00"></i>-->
                                  </div>
                                  <div class="folder_img_url" v-if="item.isMe==0">
                                      <el-tag type="success" v-if="item.PayStatus == 1 && item.paymentType == 2 " size="mini" effect="dark">已购</el-tag >
                                      <el-tag type="danger" v-else-if="item.PayStatus == 0 && item.paymentType == 2 " size="mini" effect="dark">付费</el-tag >
                                      <el-tag  v-else-if="item.PayStatus == 0 && item.paymentType == 0"  size="mini" effect="dark">免费</el-tag>

                                  </div>
                                  <div class="folder_img_url" v-if="item.isMe==1">
                                      <el-tag type="info"  size="mini" effect="dark">我的</el-tag >
                                  </div>

                                  <div class="folderName hand">{{item.title}}</div>
                                  <!-- 收藏  -->
                                  <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 10px;font-size: 20px;margin-right: 5px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
                                  <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 8px;font-size: 23px;margin-right: 5px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>

                                  <label v-if="is_login == 1 && item.uuid === user.uuid">
                                      <!--  删除  -->
                                      <div style="float: right;margin-top: 10px;font-size: 18px;margin-right: 5px;font-weight: bold;color: red" class="el-icon-delete hand" @click="deleteEnclosure(item)"></div>
                                      <!--  编辑  -->
                                      <div style="float: right;margin-top: 10px;font-size: 18px;margin-right: 5px;font-weight: bold;color: #1C7EFF" class="el-icon-edit  hand" @click="attachment(1,item)"></div>
                                  </label>
                              </div>
                          </el-popover>
                      </div>
                  </div>
              </div>
          </div>

<!--             <NoData v-if="content_list.length == 0" style="height: 1000px;"></NoData> -->
              <el-dialog :close-on-click-modal="false" :visible.sync="Favorites">
                  <div style="padding: 20px; display: flex">
                      <span style="font-size: 18px;font-weight: 600;margin-top: 8px">标签名： </span>
                      <!--  <el-input style="width: 400px" v-model="labelName"></el-input>-->
                      <el-autocomplete
                          style="width: 400px"
                          v-model="labelName"
                          :fetch-suggestions="querySearch"
                          placeholder="请输入内容"
                          @select="handleSelect_link">
                          <template slot-scope="{ item }">
                              <div class="name">{{ item.name }}</div>
                          </template>
                      </el-autocomplete>
                  </div>
                  <div style="display: flex; justify-content: end">
                      <el-button  @click="cancelLabel">取消</el-button>
                      <el-button  @click="addcollect">确定</el-button>
                  </div>
              </el-dialog>
              <el-dialog :visible.sync="uploadAttachment" width="705px" :close-on-click-modal="false" class="upload_att">
                  <addUploadAttachment ref="chment" :info=uploadAttachmentInfo></addUploadAttachment>
              </el-dialog>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileCard from "@/pages/filebase/components/FileCard.vue";
import NullBox from "@/pages/shop/components/service/NullBox.vue";
import addUploadAttachment from "@/components/common/addUploadAttachment";

export default {
  name: "",
  components: {NullBox, FileCard,addUploadAttachment},
  computed: {
    user() {
      return this.$store.state.user
    },
    is_login() {
      return this.$store.state.is_login
    }
  },
  data() {
    return {
      content: '',
      searchType: 0,
      fid: "",
      name: "",
      type3_list: [],
      // type_id:0,
      // file_type:0,
      type_index: 0,
      content_list: [],
      more: 1,
      page: 1,
      pageType: 0,
      kwtypearr: 0,
      formtype: 0,
      nonedata: false,

      codetype:1,
      bgbType:1,
        //   收藏
        Favorites:false,
        labelName:"",
        isDownloading: false,
        uploadAttachment:false,
        uploadAttachmentInfo:{
            id:0,
            auth:1,
            material_name:"",
            files:[],
            types:"",
            paytoread:false,
            input_paytoread:""
        },
    };
  },
  props: {},
  created: function () {
      var that = this;
      //预支付订单监听
      this.$EventBus.$EventBus.$off("prePayOrder");
      this.$EventBus.$on("prePayOrder", (data) => {
          this.$nextTick(() => {
              if (data.code == 100) {
                  that.bankPay(data.toPay);
              }
          });
      });

  },
  mounted: function () {
        var that = this;
          //监听支付成功
          this.$EventBus.$EventBus.$off("payMsg");
          this.$EventBus.$on("payMsg", (data) => {
              this.$nextTick(() => {
                  if (data.code == 100) {
                      that.get_content_list();
                  }
              });
          });

    if (this.$route.query.content) {
      that.content = this.$route.query.content;
    }
    if(that.$route.query.type){
        this.codetype = Number(this.$route.query.type);
    }
    that.fid = that.$route.params.fid;
    // that.type_id = that.$route.params.fid;
    that.kwtypearr = that.$route.params.fid;
    that.get_type_list();
    that.get_content_list();

    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      var ratio = 0;
      var screen = window.screen;
      var ua = navigator.userAgent.toLowerCase();
      if (window.devicePixelRatio !== "undefined") {
        ratio = window.devicePixelRatio;
      } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) { //IE
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (window.outerWidth !== "undefined" && window.innerWidth !== "undefined") {
        ratio = window.outerWidth / window.innerWidth;
      }

      if (ratio <= 1) {
        if (scrollTop + windowHeight >= scrollHeight) {
          if (that.more == 1) {
            if (that.pageType === 0) {
              that.get_content_list();
            }
            if (that.pageType === 1) {
              that.getKwMyUpload();
            }
            if (that.pageType === 2) {
              that.getMyPayKnowledge();
            }
            if (that.pageType === 3) {
              that.getKwMyCollection();
            }

          } else {
            that.sus("已加载完");
          }
        }
      } else {
        if (scrollHeight - scrollTop - windowHeight < 10) {
          if (that.more == 1) {
            that.get_content_list();
          } else {
            that.sus("已加载完");
          }
        }
      }
    }
  },
  methods: {
      //立即支付
      bankPay(data){
          var that = this;
          //判断是对公还是公绑私
          if(data.activeName != 'corporate'){
              var params = {};
              params.orderNo = data.orderNo;
              params.code = data.code;
              params.payPwd = data.payPwd;
              that.newApi.settleAccountTransferDetermine(params).then(() => {});
          }
      },
    dataSearch() {
      this.page = 1;
      this.more = 1;
      this.pageType = 0;
      this.content_list = [];
        let that = this;
        var params = {};
        params.page = that.page;
        params.pageSize = 20;
        params.kwType = that.kwtypearr;
        if (that.codetype <= 4){
            params.type = that.$route.query.type;
        }else {
            params.type = 0
        }
        params.like = that.content;
        if (that.codetype == 1 || that.codetype == 2 || that.codetype == 3 || that.codetype == 4){
            params.bgbType = 6;
        } else if(that.codetype == 5){
            params.bgbType = 7;
        } else if(that.codetype == 6){
            params.bgbType = 8;
        } else if(that.codetype == 7){
            params.bgbType = 9;
        }

        that.newApi.getFilebaseList(params).then((ret) => {
            for (var i = 0; i < ret.data.length; i++) {
                that.content_list.push(ret.data[i]);
            }
            if (ret.data.length < 20) {
                that.more = 0;
            }
            that.page++;
        }).catch((err) => {
            console.log(err)
        })
    },

    getCollection() {
      this.page = 1;
      this.more = 1;
      this.formtype = '';
      this.pageType = 3;
      this.content_list = [];
      this.getKwMyCollection()
    },

    getKwMyCollection() {
      var that = this;
      var type = "";
      if (that.formtype != 0) {
        type = that.formtype
      }
      that.newApi.getKwMyCollection({
        page: that.page,
        pageSize: 20,
        // kwType: that.kwtypearr,
        // type: type,
        // like: that.content,
        bgbType: 6
      }).then((ret) => {
        for (var i = 0; i < ret.data.length; i++) {
          that.content_list.push(ret.data[i]);
        }
        if (ret.data.length < 20) {
          that.more = 0;
        }
        if (ret.data.length == 0) {
          that.nonedata = true
        } else {
          that.nonedata = false
        }
        that.page++;
      }).catch((err) => {
        console.log(err)
      })
    },

    getPay() {
      this.page = 1;
      this.more = 1;
      this.formtype = '';
      this.pageType = 2;
      this.content_list = [];
      this.getMyPayKnowledgeList()
    },

      getMyPayKnowledgeList() {
      var that = this;
      var type = "";
      if (that.formtype != 0) {
        type = that.formtype
      }
      that.newApi.getMyPayKnowledgeList({
        page: that.page,
        pageSize: 20,
        // kwType: that.kwtypearr,
        // type: type,
        // like: that.content,
        // bgbType: 6
      }).then((ret) => {
        for (var i = 0; i < ret.data.length; i++) {
          that.content_list.push(ret.data[i]);
        }
        if (ret.data.length < 20) {
          that.more = 0;
        }
        that.page++;
      }).catch((err) => {
        console.log(err)
      })
    },

    getUpload() {
      this.page = 1;
      this.more = 1;
      this.formtype = '';
      this.pageType = 1;
      this.content_list = [];
      this.getKwMyUpload()
    },

    getKwMyUpload() {
      var that = this;
      var type = "";
      if (that.formtype != 0) {
        type = that.formtype
      }
      that.newApi.getKwMyUpload({
        page: that.page,
        pageSize: 20,
        // kwType: that.kwtypearr,
        // type: type,
        // like: that.content,
        bgbType: 6
      }).then((ret) => {
        for (var i = 0; i < ret.data.length; i++) {
          that.content_list.push(ret.data[i]);
        }
        if (ret.data.length < 20) {
          that.more = 0;
        }
        that.page++;
      }).catch((err) => {
        console.log(err)
      })
    },

    return_list() {
      //   返回按钮事件
      this.$router.go(-1);
    },

    get_type_list() {
      var that = this;
      that.newApi.getTypeList({
        lv: 3
      }).then((ret) => {
        for (var i = 0; i < ret.data.length; i++) {
          for (var j = 0; j < ret.data[i].children.length; j++) {
            if (ret.data[i].children[j].value == that.fid) {
              that.name = ret.data[i].children[j].label;
              that.type3_list = ret.data[i].children[j].children;
            } else {
              for (let g = 0; g < ret.data[i].children[j].children.length; g++) {
                if (ret.data[i].children[j].children[g].value == that.fid) {
                  console.log(ret.data[i].children[j]);
                  that.name = ret.data[i].children[j].label;
                  that.fid = ret.data[i].children[j].value;
                  that.type3_list = ret.data[i].children[j].children;
                  that.type_index = g + 1;
                }
              }
            }
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    get_content_list: function () {
        var that = this;

        var params = {}
        params.page = that.page;
        params.pageSize = 20;
        params.kwType = that.kwtypearr;
        if (that.$route.query.type <= 4){
            params.type = that.$route.query.type;
        }else {
            params.type = 0
        }
        params.like = that.content;
        if (that.$route.query.type == 1 || that.$route.query.type == 2 || that.$route.query.type == 3 || that.$route.query.type == 4){
            params.bgbType = 6;
        } else if(that.$route.query.type == 5){
            params.bgbType = 7;
        } else if(that.$route.query.type == 6){
            params.bgbType = 8;
        } else if(that.$route.query.type == 7){
            params.bgbType = 9;
        }

      that.newApi.getFilebaseList(params).then((ret) => {
        for (var i = 0; i < ret.data.length; i++) {
          that.content_list.push(ret.data[i]);
        }
        if (ret.data.length < 20) {
          that.more = 0;
        }
        that.page++;
      }).catch((err) => {
        console.log(err)
      })

    },
    searchTypeChange(type) {
      this.searchType = type;
      this.formtype = 0;
      this.page = 1;
      this.more = 1;
      this.content_list = [];
      this.get_content_list();
    },
    type_change(kwtypearr, index) {
      this.kwtypearr = kwtypearr;
      this.type_index = index;
      this.formtype = 0;
      this.page = 1;
      this.more = 1;
      this.content_list = [];
      this.get_content_list();
    },
    file_type_change(formtype) {
      this.page = 1;
      this.more = 1;
      this.pageType = 0;
      this.formtype = formtype;
      this.content_list = [];
      this.get_content_list();
    },
    content_info: function (id) {
      var that = this;
      if (!this.utils.toAuth(1)) {
        return false
      }
        window.open('/content/info/' + id + "/2");
    },
      dataclassification(){
        let that = this;
        if (that.codetype === 1 || that.codetype === 2 || that.codetype === 3 || that.codetype === 4){
             that.fidtype = 6;
        }else if(that.codetype === 5){
            that.fidtype = 7
        }else if(that.codetype === 6){
            that.fidtype = 8
        }else if(that.codetype === 7){
            that.fidtype = 9
        }
          if (that.codetype === 1){
              that.kwtype = 1;
          }else if(that.codetype === 2){
              that.kwtype = 2
          }else if(that.codetype === 3){
              that.kwtype = 3
          }else if(that.codetype === 4){
              that.kwtype = 4
          }else if(that.codetype === 5 || that.codetype === 6 || that.codetype === 7){
              that.kwtype = 0
          }

          that.newApi.getFilebaseList({
              page: 1,
              pageSize: 40,
              kwType: that.kwtypearr,
              type: that.kwtype,
              like: that.content,
              bgbType: that.fidtype
          }).then((ret) => {
              // for (var i = 0; i < ret.data.length; i++) {
              //     that.content_list.push(ret.data[i]);
              // }
              // if (ret.data.length < 20) {
              //     that.more = 0;
              // }
              that.content_list = ret.data
              // that.page++;
          }).catch((err) => {
              console.log(err)
          })

      },
      content_info_type:function(id,payAmt,uuid,bgbType,PayStatus){
          var that = this;
          that.kwId = id;
          that.clickBgbType = bgbType;
        if (!this.utils.toAuth(1)) {
          return false
        }
              that.utils.a('/content/picture/'+id);
      },
      // 收藏
      addFavorite(id,bgbtype) {
          if(this.is_login === 0){
              this.$store.commit('setShowLoginPopup', true);
              return
          }
          this.Favorites = true;
          this.selectedItemId = id;
          this.selectedbgbtype = bgbtype
          this.getUserCollectionLabelList()
      },
      // 取消标签
      cancelLabel() {
          this.labelName = '';
          this.Favorites = false;
      },
      async getUserCollectionLabelList() {
          try {
              const response = await this.newApi.getUserCollectionLabelList({});
              if (response.isSuccess === 1) {
                  this.restaurants = response.data;
              }
          } catch (error) {
              console.error(error);
          }
      },
      async querySearch(queryString, cb) {
          if (this.restaurants.length === 0) {
              // 如果填写的数据为空，先加载数据
              await this.getUserCollectionLabelList();
          }

          // 进行搜索
          const results = queryString
              ? this.restaurants.filter(restaurant =>
                  restaurant.name.toLowerCase().includes(queryString.toLowerCase())
              )
              : this.restaurants;

          // 调用 callback 返回建议列表的数据
          cb(results);
      },
      handleSelect_link(item) {
          this.labelName = item.name; // 将选择的名称赋值给 labelName
          this.selectedRestaurant = item;
      },
      // 收藏
      addcollect(){
          this.newApi.addCollection({
              bgbType: this.selectedbgbtype,
              label: this.labelName,
              id: this.selectedItemId
          }).then(res => {
              if (res.isSuccess == 1) {
                  this.labelName = '';
                  this.Favorites = false;
                  this.getFilebaseList(this.selectedbgbtype)
                  this.utils.sus(res.data);
              }
          })
      },
      // 取消收藏
      cancelCollection(id,bgbType){
          this.newApi.cancelCollection({
              id: id,
              bgbType: bgbType,
          }).then(res =>{
              if (res.isSuccess == 1) {
                  this.utils.sus(res.data)
                  this.getFilebaseList(bgbType)
              }
          })
      },
      // 点击查询全部
      getFilebaseList(bgbType){
          var that = this;
          this.newApi.getFilebaseList({
              page: 1,
              pageSize: 40,
              bgbType:bgbType,
              like:"",
          }).then((res)=>{
              if (res.isSuccess == 1){
                  that.content_list = res.data;
              }
          }).catch((err)=>{
              console.log(err)
          })
      },
      //  附件删除
      deleteEnclosure(item){
          var that = this;
          this.utils.confirm("确定删除吗？", () => {
              that.newApi.delKnowledge({
                  id:item.id,

              }).then((res)=>{
                  if (res.isSuccess == 1) {
                      that.utils.sus(res.data);
                      this.getFilebaseList(9)
                  }
              }).catch((err)=>{
                  console.log(err)
              })
          })
      },
      //  附件编辑
      attachment(type,item){
          var that = this;
        if (!this.utils.toAuth(1)) {
          return false
        }
              if (type==1){
                  if (item.paymentType != 0){
                      //消息提示
                      this.utils.confirm("付费素材不可编辑");return false;
                  }
                  that.uploadAttachmentInfo.auth = item.auth;
                  that.uploadAttachmentInfo.files = item.enclosure;
                  that.uploadAttachmentInfo.material_name = item.title;
                  that.uploadAttachmentInfo.types = JSON.parse(item.kwTypeArr);
                  that.uploadAttachmentInfo.id = item.id;
              }
              this.uploadAttachment  = true;

      },
      // 父组件接收子组件取消编辑附件
      complete(){
          this.uploadAttachment = false;
          this.uploadAttachmentInfo.id =0;
          this.uploadAttachmentInfo.auth =1;
          this.uploadAttachmentInfo.files = [];
          this.uploadAttachmentInfo.material_name = "";
          this.uploadAttachmentInfo.paytoread = false;
          this.uploadAttachmentInfo.input_paytoread = "";
          this.uploadAttachmentInfo.types = "";
      },
      download(url,name,hash,id,PayStatus,payAmt){
          var that = this;
        if (!this.utils.toAuth(1)) {
          return false
        }
              if (PayStatus == 0 && payAmt > 0){
                  this.payKnowledge(id)
              }else {
                  this.isDownloading = true;  //禁用按钮
                  this.utils.download(url,name,hash)
                  setInterval(() => {
                      this.isDownloading = false;
                  }, 5000)
              }
      },
      payKnowledge(id){
          var that = this;
          that.newApi.payKnowledge({
              knowledgeId:id,
              paidUuid:that.user.uuid,
          }).then((res)=>{
              if (res.isSuccess == 1) {
                  // <AaDaPayCode ref="aDaPayCode" type="2" payType="14"  :memberId="paidKnowledgeinfo.memberId"  :payAmt="paidKnowledgeinfo.payAmt" :collectionUuid="paidKnowledgeinfo.addUuid" :orderNo="orderNo" @change="changeOrder($event)"></AaDaPayCode>
                  let params = that.$store.state.payParams;
                  params.type = 2;
                  params.payType = 14;
                  params.payAmt = res.data.payAmt;
                  params.collectionUuid = res.data.addUuid;
                  params.memberId = res.data.memberId;
                  params.orderNo = res.data.orderNo;
                  that.sign = that.utils.random_string(6);
                  that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                      that.b('/newAdaPay/?sign='+that.sign);
                  })
              }
          }).catch((err)=>{
              console.log(err)
          })
      },
  }
}
</script>
<style lang="scss" scoped>
        #enclosure_list{
            margin-bottom: 10px;height: auto;display: flex; gap:11px;
            padding:5px 30px; background: #FFF;
            overflow: hidden;
            .col{
                flex:1;
                .folder{
                    width: 100%;
                    margin-bottom: 10px;
                    padding : 10px 0;
                    background: #f4f4f4;
                    box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);
                    border-radius: 8px;
                    .folderImgAndName{
                        overflow: auto;
                        height: 40px;
                        margin-left: 5px;
                            .folderImg{
                                width: 26px;height: 24px; margin: 7.5px;
                                float: left;
                            }
                                .folder_img_url{
                                    width: 32px;
                                    float: left;
                                    line-height: 40px;
                                    margin-right: 10px;
                                }
                                .folderName{
                                    float: left;color: #555555;font-size:17px;text-overflow :ellipsis;overflow: hidden;
                                    white-space :nowrap;margin-left: 2.5px;font-weight: bold;
                                    height:40px;line-height: 40px;
                                    max-width: 120px;
                                }
                        }
                        .folder_border {
                            float: left;
                            width: 100%;
                            border-top: 1px solid #CECFD0;
                        }
                    .folder_more {
                        clear: both;
                        text-align: center;
                        padding-top: 10px;;
                        font-size: 14px;
                        cursor: pointer
                    }
                    .fileList{
                        width: 330px;
                        margin: 0 auto;
                        height: 40px;
                            .file{
                                cursor: pointer;background: #e3d8d8;margin-bottom: 8px; overflow: hidden;border-radius: 3px;
                            img{
                                width:22px;height:26px;float: left;
                                margin: 11px 10px 11px 16px;
                            }
                            span{
                                height:48px;line-height:48px; width:245px;color:#000;float: left; text-overflow :ellipsis;white-space :nowrap;overflow : hidden;
                            }
                            i {
                                float: left;
                            }
                        }

                    }

                }
            }
        }
</style>
<style scoped>
.label {
  height: 28px;
  line-height: 28px;
  width: 100px;
  text-align: center;
  background-color: #EEEEEE;
  margin-right: 1%;
  float: left;
  border-radius: 5px;
  font-size: 17px;
  overflow: hidden;
}

.room_tab {
  margin-left: 40px;
  line-height: 40px;
  font-size: 18px;
  width: auto;
  font-weight: bold;
  text-align: center;
}

.room_tab_change {
  margin-left: 20px;
  line-height: 40px;
  font-size: 18px;
  color: #5A6FE6;
  width: auto;
  font-weight: bold;
  text-align: center;
}

.room_type_bottom {
  margin-top: 4px;
  border-bottom: 4px solid #5A6FE6;
  font-weight: bold;
}

.el-radio {
  color: #000000;
  font-size: 17px;

}

/deep/ .el-radio__label {
  font-family: "Microsoft YaHei";
  font-size: 17px;
  box-sizing: content-box;

}

.return {
  margin-left: 20px;
}

/deep/ .el-empty__image {
  width: 260px;
}

/deep/ .el-radio__label {
    font-weight: 600;
}
</style>
<style>
.upload_att .el-dialog__body {
    padding: 28px 2px !important;
}
.upload_att .el-dialog__header {
    padding: 0px !important;
}
</style>
